import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Grid,
  Divider,
  Button,
  CircularProgress,
  Chip,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  Download as DownloadIcon,
  Preview as PreviewIcon,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import SearchCertificate from "./SearchCertificate";

const SearchDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [search, setSearch] = useState(null);
  const [parcel, setParcel] = useState(null);
  const [ownership, setOwnership] = useState(null);
  const [valuation, setValuation] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);

  const fetchSearchDetails = async () => {
    setLoading(true);
    try {
      // Fetch search details
      const searchRes = await fetch(`/api/searches/${id}`);
      const searchData = await searchRes.json();
      setSearch(searchData);

      // Fetch parcel details
      const parcelRes = await fetch(`/api/parcels/${searchData.plotNumber}`);
      const parcelData = await parcelRes.json();
      setParcel(parcelData);

      // Fetch ownership details
      const ownershipRes = await fetch(
        `/api/ownerships/${searchData.plotNumber}`
      );
      const ownershipData = await ownershipRes.json();
      setOwnership(ownershipData);

      // Fetch valuation details
      const valuationRes = await fetch(
        `/api/valuations/${searchData.plotNumber}`
      );
      const valuationData = await valuationRes.json();
      setValuation(valuationData);
    } catch (error) {
      console.error("Error fetching details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSearchDetails();
  }, [id]);

  const handleComplete = async () => {
    setSaving(true);
    try {
      const response = await fetch(`/api/searches/${id}/status`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status: "Completed",
          propertySection: {
            size: parcel?.size,
            use: parcel?.use,
            location: parcel?.location,
            // Add other property details
          },
          proprietorshipSection: [
            {
              owner: ownership?.currentOwner,
              idNumber: ownership?.idNumber,
              // Add other ownership details
            },
          ],
          encumbranceSection: [], // Add any encumbrances if available
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to complete search");
      }

      const updatedSearch = await response.json();
      setSearch(updatedSearch);
    } catch (error) {
      console.error("Error completing search:", error);
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "400px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ margin: "3rem 1rem" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h5">Search Details</Typography>
        {/* <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="outlined"
            startIcon={<PreviewIcon />}
            onClick={() => setPreviewOpen(true)}
          >
            Preview Certificate
          </Button>
          <PDFDownloadLink
            document={
              <SearchCertificate
                search={search}
                parcel={parcel}
                ownership={ownership}
                valuation={valuation}
              />
            }
            fileName={`search-certificate-${search?.reference}.pdf`}
          >
            {({ loading: pdfLoading }) => (
              <Button
                variant="contained"
                startIcon={<DownloadIcon />}
                disabled={pdfLoading}
              >
                Download Certificate
              </Button>
            )}
          </PDFDownloadLink>
          {search?.status !== "Completed" && (
            <Button
              variant="contained"
              color="success"
              onClick={handleComplete}
              disabled={saving}
            >
              {saving ? <CircularProgress size={24} /> : "Complete Search"}
            </Button>
          )}
        </Box> */}
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Application Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  Reference Number
                </Typography>
                <Typography variant="body1">{search?.reference}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  Status
                </Typography>
                <Chip
                  label={search?.status}
                  color={
                    search?.status === "Ongoing"
                      ? "warning"
                      : search?.status === "Completed"
                      ? "success"
                      : "error"
                  }
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  Submission Date
                </Typography>
                <Typography variant="body1">
                  {dayjs(search?.createdAt).format("MMM D, YYYY h:mm A")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  Plot Number
                </Typography>
                <Typography variant="body1">{search?.plotNumber}</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Property Details
            </Typography>
            <Grid container spacing={2}>
              {/* Display parcel details */}
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  Size
                </Typography>
                <Typography variant="body1">{parcel?.size} Ha</Typography>
              </Grid>
              {/* Add more parcel details */}
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Ownership Information
            </Typography>
            <Grid container spacing={2}>
              {/* Display ownership details */}
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="text.secondary">
                  Current Owner
                </Typography>
                <Typography variant="body1">
                  {ownership?.currentOwner}
                </Typography>
              </Grid>
              {/* Add more ownership details */}
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Valuation Information
            </Typography>
            <Grid container spacing={2}>
              {/* Display valuation details */}
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  Current Value
                </Typography>
                <Typography variant="body1">
                  KES {valuation?.currentValue}
                </Typography>
              </Grid>
              {/* Add more valuation details */}
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Applicant Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  Applicant Name
                </Typography>
                <Typography variant="body1">{search?.applicantName}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  Phone Number
                </Typography>
                <Typography variant="body1">
                  {search?.applicantPhone}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  ID Number
                </Typography>
                <Typography variant="body1">{search?.idNumber}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  Purpose of Search
                </Typography>
                <Typography variant="body1">
                  {search?.purposeOfSearch}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      {/* Certificate Preview Dialog */}
      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogContent sx={{ height: "80vh", p: 0 }}>
          <PDFViewer width="100%" height="100%" style={{ border: "none" }}>
            <SearchCertificate
              search={search}
              parcel={parcel}
              ownership={ownership}
              valuation={valuation}
            />
          </PDFViewer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SearchDetails;
