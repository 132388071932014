import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Box, CircularProgress} from "@mui/material";
import Navbar from "./Util/Navbar";
import Plots from "../Sections/Plots";
import Payments from "../Sections/Payments";
import Support from "../Sections/Support";
import Search from "../components/ParcelSearch/ParcelSearch";
import NewSearch from "../components/ParcelSearch/NewApplication";
import SearchDetails from "../components/ParcelSearch/SearchDetails";
import Dashboard from "../Pages/Dashboard";
import Settings from "../Pages/Settings";
import NotFound from "../Pages/NotFound"


function PageRoutes() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate fetching user data
    const fetchUser = async () => {
      setTimeout(() => {
        setUser({ name: "John Doe" }); // Mock user data
        setLoading(false);
      }, 1000); // Simulated delay
    };

    fetchUser();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Navbar user={user} setUser={setUser} />
        <Box component="main" sx={{ flexGrow: 1, mt: 5 }}>
          <Routes>
            <Route path="/home" element={<Dashboard />} />
            <Route path="plots" element={<Plots />} />
            <Route path="payments" element={<Payments />} />
            <Route path="support" element={<Support />} />
            <Route path="search" element={<Search />} />
            <Route path="search/new" element={<NewSearch />} />
            <Route
              path="searches/:id"
              element={<SearchDetails user={user} />}
            />
            <Route path="settings" element={<Settings />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "55vh",
        }}
      >
        {/* Main content */}
        <Box sx={{ flex: 1, padding: 2 }}>
          {/* Your page content goes here */}
        </Box>
      </Box>
    </Box>
  );
}

export default PageRoutes;
