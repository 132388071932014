import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import dayjs from "dayjs";

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 12,
  },
  header: {
    textAlign: "center",
    marginBottom: 20,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
    borderBottom: "1 solid black",
    paddingBottom: 5,
  },
  row: {
    flexDirection: "row",
    marginBottom: 5,
  },
  label: {
    width: "30%",
    fontWeight: "bold",
  },
  value: {
    width: "70%",
  },
  footer: {
    position: "absolute",
    bottom: 40,
    left: 40,
    right: 40,
  },
  signature: {
    marginTop: 50,
    borderTop: "1 solid black",
    paddingTop: 10,
  },
});

const SearchCertificate = ({ search, parcel, ownership, valuation }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.title}>REPUBLIC OF KENYA</Text>
        <Text style={styles.subtitle}>THE LAND REGISTRATION ACT</Text>
        <Text>(Cap. 300)</Text>
        <Text style={[styles.title, { marginTop: 20 }]}>
          CERTIFICATE OF OFFICIAL SEARCH
        </Text>
      </View>

      <View style={styles.section}>
        <Text>
          On the {dayjs().format("DD")} day of {dayjs().format("MMMM YYYY")},
          the following subsisting entries on the register of the
          above-mentioned title were found:
        </Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>PART A - Property Section</Text>
        <View style={styles.row}>
          <Text style={styles.label}>Plot No:</Text>
          <Text style={styles.value}>{search?.plotNumber}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Approximate area:</Text>
          <Text style={styles.value}>{parcel?.size} Hectares</Text>
        </View>
        {/* Add more property details */}
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>PART B - Proprietorship Section</Text>
        {ownership?.currentOwner && (
          <View style={styles.row}>
            <Text style={styles.label}>Current Owner:</Text>
            <Text style={styles.value}>{ownership.currentOwner}</Text>
          </View>
        )}
        {/* Add more ownership details */}
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          PART C - Encumbrances section (Leases, Charges, etc.)
        </Text>
        <Text>The following applications are pending:</Text>
        {/* Add encumbrances if any */}
      </View>

      <View style={styles.footer}>
        <Text>The certified copies requested are attached.</Text>
        <Text>
          Dated this {dayjs().format("DD")} day of {dayjs().format("MMMM YYYY")}
        </Text>

        <View style={styles.signature}>
          <Text>Land Registrar,</Text>
          <Text>HOMA BAY Land Registry.</Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default SearchCertificate;
