import React, { useState } from "react";
import {
  Box,
  Card,
  Typography,
  TextField,
  Button,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const steps = ["FAQs", "Search details", "Confirmation"];

const FAQSection = () => (
  <Box>
    <Typography variant="subtitle1" gutterBottom>
      Frequently Asked Questions about Parcel Search
    </Typography>

    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
      <Box>
        <Typography variant="subtitle2" color="primary" gutterBottom>
          What is an Official Search?
        </Typography>
        <Typography variant="body2" color="text.secondary">
          An official search is a formal inquiry into the records of a
          particular parcel of land maintained by the Land Registry. It provides
          current information about ownership, encumbrances, and other
          registered interests affecting the property.
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle2" color="primary" gutterBottom>
          What information do I need to conduct a search?
        </Typography>
        <Typography variant="body2" color="text.secondary">
          You will need:
          <ul>
            <li>The correct parcel number or title number</li>
            <li>Your identification details (ID/Passport)</li>
            <li>Purpose of the search</li>
            <li>Payment of prescribed fees</li>
          </ul>
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle2" color="primary" gutterBottom>
          What will the search reveal?
        </Typography>
        <Typography variant="body2" color="text.secondary">
          The search can reveal:
          <ul>
            <li>Current ownership details</li>
            <li>Size and location of the parcel</li>
            <li>
              Any registered encumbrances (charges, cautions, restrictions)
            </li>
            <li>Lease details (if leasehold)</li>
            <li>Any pending transactions</li>
          </ul>
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle2" color="primary" gutterBottom>
          How long is the search valid?
        </Typography>
        <Typography variant="body2" color="text.secondary">
          An official search certificate is typically valid for 90 days from the
          date of issue. However, it represents the position of the register at
          the time of search only.
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle2" color="primary" gutterBottom>
          Important Notes:
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <ul>
            <li>Ensure the parcel number is correct to avoid delays</li>
            <li>The search fee is non-refundable</li>
            <li>Processing time may vary depending on the registry workload</li>
            <li>
              Additional documents may be required for certain types of searches
            </li>
          </ul>
        </Typography>
      </Box>
    </Box>
  </Box>
);

const NewApplication = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    plotNumber: "",
    applicantName: "",
    applicantPhone: "",
    idNumber: "",
    typeOfSearch: "Parcel search",
    purposeOfSearch: "",
    searchScope: {
      property: true,
      proprietorship: true,
      encumbrance: true,
    },
  });

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handleNext = () => {
    if (activeStep === 1 && !validateSearchDetails()) {
      return;
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const validateSearchDetails = () => {
    const requiredFields = [
      "plotNumber",
      "applicantName",
      "applicantPhone",
      "idNumber",
    ];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      showSnackbar("Please fill in all required fields", "error");
      return false;
    }
    return true;
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch("/api/searches", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to submit application");
      }

      const data = await response.json();
      showSnackbar("Search application submitted successfully");
      navigate("/search");
    } catch (error) {
      console.error("Submit error:", error);
      showSnackbar(error.message || "Failed to submit application", "error");
    } finally {
      setLoading(false);
    }
  };

  const renderSearchDetails = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          label="Parcel Number"
          placeholder="Enter title number or parcel number"
          value={formData.plotNumber}
          onChange={(e) =>
            setFormData({ ...formData, plotNumber: e.target.value })
          }
          helperText="Hint: Ensure to use the correct parcel number"
          error={!formData.plotNumber}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          label="Purpose of search"
          placeholder="Purpose of search"
          multiline
          value={formData.purposeOfSearch}
          onChange={(e) =>
            setFormData({ ...formData, purposeOfSearch: e.target.value })
          }
          error={!formData.purposeOfSearch}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          label="Applicant Name"
          value={formData.applicantName}
          onChange={(e) =>
            setFormData({ ...formData, applicantName: e.target.value })
          }
          error={!formData.applicantName}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          label="Applicant Phone Number"
          value={formData.applicantPhone}
          onChange={(e) =>
            setFormData({ ...formData, applicantPhone: e.target.value })
          }
          error={!formData.applicantPhone}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          label="ID Number"
          value={formData.idNumber}
          onChange={(e) =>
            setFormData({ ...formData, idNumber: e.target.value })
          }
          error={!formData.idNumber}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" gutterBottom>
          Scope of search
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.searchScope.property}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  searchScope: {
                    ...formData.searchScope,
                    property: e.target.checked,
                  },
                })
              }
            />
          }
          label="Particulars noted on the Property section"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.searchScope.proprietorship}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  searchScope: {
                    ...formData.searchScope,
                    proprietorship: e.target.checked,
                  },
                })
              }
            />
          }
          label="Proprietorship section"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.searchScope.encumbrance}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  searchScope: {
                    ...formData.searchScope,
                    encumbrance: e.target.checked,
                  },
                })
              }
            />
          }
          label="Encumbrance section"
        />
      </Grid>
    </Grid>
  );

  const renderConfirmation = () => (
    <Box>
      <Typography variant="subtitle1" gutterBottom>
        Confirmation
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" sx={{ fontWeight: "bold" }}>
                No.
              </TableCell>
              <TableCell>1</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" sx={{ fontWeight: "bold" }}>
                Parcel
              </TableCell>
              <TableCell>{formData.plotNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" sx={{ fontWeight: "bold" }}>
                Applicant:
              </TableCell>
              <TableCell>
                {formData.applicantName}, ID NO. {formData.idNumber}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" sx={{ fontWeight: "bold" }}>
                Type of search:
              </TableCell>
              <TableCell>{formData.typeOfSearch}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" sx={{ fontWeight: "bold" }}>
                Purpose of search:
              </TableCell>
              <TableCell>{formData.purposeOfSearch}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" sx={{ fontWeight: "bold" }}>
                Scope of search:
              </TableCell>
              <TableCell>
                {[
                  formData.searchScope.property &&
                    "Particulars noted on the Property section",
                  formData.searchScope.proprietorship &&
                    "Proprietorship section",
                  formData.searchScope.encumbrance && "Encumbrance section",
                ]
                  .filter(Boolean)
                  .join(" / ")}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <FAQSection />;
      case 1:
        return renderSearchDetails();
      case 2:
        return renderConfirmation();
      default:
        return null;
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Search: New Application
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 3 }}>
        Hint: Ensure to use the correct title number or parcel number
      </Typography>

      <Card sx={{ p: 3 }}>
        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 1000,
            }}
          >
            <CircularProgress />
          </Box>
        )}

        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {renderStepContent(activeStep)}

        <Box
          sx={{ display: "flex", justifyContent: "flex-end", mt: 3, gap: 2 }}
        >
          <Button
            variant="outlined"
            onClick={handleBack}
            disabled={activeStep === 0 || loading}
          >
            Back
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          ) : (
            <Button variant="contained" onClick={handleNext} disabled={loading}>
              Next
            </Button>
          )}
        </Box>
      </Card>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NewApplication;
