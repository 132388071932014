import {
  Box,
  Typography,
  Grid,
  TextField,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import { useState } from "react";

export default function Dashboard() {
  const userName = localStorage.getItem("userName");
  
  return (
    <Box sx={{ margin: "3rem 1rem" }}>
      <Typography variant="h6" color="primary" sx={{ fontWeight: "600" }}>
        Hi {userName}, welcome
      </Typography>
      <Typography variant="subtitle1" sx={{ marginBottom: "1rem" }}>
        Home / Dashboard
      </Typography>

      <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
        Services
      </Typography>

      <TextField
        variant="outlined"
        placeholder="Find Service"
        fullWidth
        sx={{ marginBottom: "2rem" }}
      />

      <Grid container spacing={3}>
        {[
          "Land Registration",
          "Land Administration",
          "Physical Planning",
          "Survey & Mapping",
        ].map((category, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h6">{category}</Typography>
                <Typography variant="body2" sx={{ marginBottom: "1rem" }}>
                  {category === "Land Registration" && (
                    <>
                      Search
                      <br />
                      Transfer
                      <br />
                      Charge
                    </>
                  )}
                  {category === "Land Administration" && (
                    <>
                      Ground Rent
                      <br />
                      Change of User
                      <br />
                      Extension of User
                    </>
                  )}
                  {category === "Physical Planning" && (
                    <>
                      Approval of Part Development Plans
                      <br />
                      Planning Documents Requisition
                      <br />
                      Certificate of Compliance
                    </>
                  )}
                  {category === "Survey & Mapping" && (
                    <>
                      Subdivision
                      <br />
                      Re-survey
                      <br />
                      New Grant
                    </>
                  )}
                </Typography>
                <Button variant="outlined">View More</Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
