import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Tabs,
  Tab,
  Chip,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import { Add as AddIcon, Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

// Initialize dayjs plugins
dayjs.extend(relativeTime);

const formatDate = (dateString) => {
  if (!dateString) return "";
  try {
    return dayjs(dateString).format("MMM D, YYYY h:mm A");
  } catch (error) {
    console.error("Invalid date:", dateString);
    return "";
  }
};

const formatElapsedTime = (dateString) => {
  if (!dateString) return "";
  try {
    return dayjs(dateString).fromNow();
  } catch (error) {
    console.error("Invalid date:", dateString);
    return "";
  }
};

const ParcelSearch = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ data: [], total: 0 });
  const ownerId = localStorage.getItem("ownershipId");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const navigate = useNavigate();

  const tabs = [
    { label: "Ongoing", count: 0 },
    { label: "Completed", count: 0 },
    { label: "Rejected", count: 0 },
  ];

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  const fetchSearches = async () => {
    setLoading(true);
    try {
      const status = tabs[activeTab].label;
      const response = await fetch(
        `/api/searches?offset=${
          paginationModel.page * paginationModel.pageSize
        }&limit=${paginationModel.pageSize}&status=${status}&ownerId=${ownerId}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch searches");
      }

      const result = await response.json();

      const processedData = {
        ...result,
        data: result.data.map((row) => ({
          ...row,
          createdAt: row.createdAt || row.updatedAt || new Date().toISOString(),
          id: row.id || row._id,
        })),
      };

      setData(processedData);

      const newTabs = [...tabs];
      newTabs[activeTab].count = result.total;
    } catch (error) {
      console.error("Error fetching searches:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSearches();
  }, [paginationModel, activeTab]);

  const columns = [
    {
      field: "index",
      headerName: "No",
      width: 70,
      renderCell: (params) => {
        const index = data.data.findIndex((e) => e.id === params?.row?.id);
        return (
          <Chip
            label={index + 1 + paginationModel.page * paginationModel.pageSize}
          />
        );
      },
    },
    {
      field: "reference",
      headerName: "Reference number",
    },
    {
      field: "plotNumber",
      headerName: "Plot No",
    },
    {
      field: "applicantName",
      headerName: "Applicant Name",
      flex: 1,
    },
    {
      field: "applicantPhone",
      headerName: "Phone Number",
    },
    {
      field: "createdAt",
      headerName: "Date of submission",
      renderCell: (params) => {
        if (!params?.value) return "";
        try {
          return dayjs(params.value).format("MMM D, YYYY h:mm A");
        } catch (error) {
          console.error("Date formatting error:", error);
          return "";
        }
      },
    },
    {
      field: "elapsedTime",
      headerName: "Elapsed time",
      renderCell: (params) => {
        if (!params?.row?.createdAt) return "";
        try {
          return dayjs(params.row.createdAt).fromNow();
        } catch (error) {
          console.error("Elapsed time calculation error:", error);
          return "";
        }
      },
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => (
        <Chip
          label={params?.value || "Unknown"}
          color={
            params?.value === "Ongoing"
              ? "warning"
              : params?.value === "Completed"
              ? "success"
              : "error"
          }
          size="small"
        />
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Visibility />}
          label="View"
          onClick={() => navigate(`/searches/${params.id}`)}
        />,
      ],
    },
  ];

  return (
    <Box sx={{ margin: "3rem 1rem" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="h6" color="primary" sx={{ fontWeight: "600" }}>
          Search Applications
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigate("/search/new")}
        >
          New Application
        </Button>
      </Box>

      <Card sx={{ p: 2, position: "relative", height: 600 }}>
        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 1000,
            }}
          >
            <CircularProgress />
          </Box>
        )}

        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                sx={{ textTransform: "capitalize" }}
                label={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    {tab.label}
                    <Chip
                      label={tab.count}
                      size="small"
                      sx={{ backgroundColor: "#f0f0f0" }}
                    />
                  </Box>
                }
              />
            ))}
          </Tabs>
        </Box>

        <DataGrid
          rows={data.data}
          columns={columns}
          rowCount={data.total}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 20, 50, 100]}
          loading={loading}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          disableRowSelectionOnClick
          autoHeight
          sx={{
            border: "none",
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }}
        />
      </Card>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ParcelSearch;
